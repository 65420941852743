import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import useCanShareReward from 'hooks/use-can-share-reward';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import SkeletonSubscriptionView from 'views/subscription/skeleton';
import { useNavigationContext } from 'contexts/navigation-context';

import { useNoticationContext } from 'contexts/notification-context';
import { isAuthenticated } from 'services/authentication-service';
import { getPassesById } from 'state/selectors';

const LazyShareIos = loadable(() => import('suspended-page/loyalty-card/share/ios'));

const ShareIosPage = ({ location }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const [loading, setLoading] = useState(true);
  const [passToken, setPassToken] = useState('');
  const [title, setTitle] = useState('');
  const { canShare, canShareRewardLoading } = useCanShareReward();

  const {
    homePageOnly = () => {},
    notSubscribed = () => {},
  } = useNavigationContext();
  const noticationContext = useNoticationContext();
  const authenticated = isAuthenticated();

  useEffect(() => {
    async function fetchPassData() {
      const { name = '' } = await getPassesById(passId);
      setTitle(name);
      const getPassTokenService = await import('services/passes/pass-token-service');
      const { getPassToken } = getPassTokenService.default({ noticationContext });
      const token = await getPassToken(passId);
      if (token) {
        setPassToken(token);
        setLoading(false);
      } else {
        setPassToken('boom-town-redirect');
      }
    }
    fetchPassData();
    // eslint-disable-next-line
  }, []);

  if (passToken === 'boom-town-redirect') {
    homePageOnly();
    return (<SkeletonSubscriptionView />);
  }

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (!canShare) {
    notSubscribed();
    return (<SkeletonSubscriptionView />);
  }

  return (
    <LazyShareIos
      passId={passId}
      title={title}
      passToken={passToken}
      loading={loading && canShareRewardLoading} />
  );
};

ShareIosPage.propTypes = { location: object };

export default ShareIosPage;
